import { useState, useEffect, useRef } from 'react';
import cotastatsimg from '../../shared/assets/charactersheets/cotastats.png';
//import cotalogo from '../../shared/assets/logos/cotalogo.png';
//import cotatitle from '../../shared/assets/logos/cotatitle.png';
import '../../shared/styles/Page.css';
import CotaCharacterSheet from '../../features/character-sheet/components/CotaCharacterSheet';
import TrialmentCharacterSheet from '../../features/character-sheet/components/TrialmentCharacterSheet';
import TopBar from '../../layouts/TopBar';
import DiceBox from '@3d-dice/dice-box';

// Store the diceBox instance globally to prevent recreation on component remounts
let globalDiceBox = null;

export default function Campaign() {
  const [system] = useState(localStorage.getItem('system'));
  const [campaign] = useState(localStorage.getItem('campaign'));
  const [mode, setMode] = useState('light');
  const [diceBoxReady, setDiceBoxReady] = useState(false);
  const diceBoxRef = useRef(null);

  useEffect(() => {
    // Only create and initialize the dice box if it doesn't already exist
    if (!globalDiceBox) {
      globalDiceBox = new DiceBox('#dice-box', {
        id: 'dice-canvas',
        assetPath: '/assets/dice-box/',
        startingHeight: 10,
        throwForce: 6,
        spinForce: 5,
        lightIntensity: 0.9,
        scale: 4,
        themeColor: '#e94f37',
      });
      
      // Initialize the dice box
      try {
        globalDiceBox.init().then(() => {
          console.log('Dice box initialized successfully');
          diceBoxRef.current = globalDiceBox;
          setDiceBoxReady(true);
        }).catch(error => {
          console.error('Failed to initialize dice box:', error);
        });
      } catch (error) {
        console.warn('Dice box initialization error:', error.message);
      }
    } else {
      // If the dice box already exists, just use it
      console.log('Using existing dice box instance');
      diceBoxRef.current = globalDiceBox;
      setDiceBoxReady(true);
    }
    
    const savedData = localStorage.getItem('appearance');
    if (savedData) {
      const { mode } = JSON.parse(savedData);
      setMode(mode);
    }
    
    // No cleanup needed - we want to keep the globalDiceBox alive
  }, []);

  useEffect(() => {
    const appearance = {
      mode,
    };
    localStorage.setItem('appearance', JSON.stringify(appearance));
  }, [mode]);

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  return (
    <div className='page_container'>
      <div className={'page_top page_top' + darkMode()}>
        <TopBar mode={mode} setMode={setMode} />
      </div>
      <div className='page_content'>
        <div className='page_1'>
          {diceBoxReady ? (
            system && system === 'trialment' ? (
              <TrialmentCharacterSheet
                campaign={campaign}
                mode={mode}
                editable={true}
                onModal={false}
                dmView={false}
                roll={true}
                diceBox={diceBoxRef.current}
              />
            ) : ( 
              <CotaCharacterSheet
                mode={mode}
                editable={true}
                onModal={false}
                dmView={false}
                roll={true}
                diceBox={diceBoxRef.current}
              />
            )
          ) : (
            <div className="loading-dice">
              <p>Initializing dice box...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
