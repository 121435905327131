import Rodal from 'rodal';
import { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { Button } from '../../../shared/components/Button';
import 'rodal/lib/rodal.css';
import '../../../shared/styles/Cota.css';
import { combatScrolls } from './CotaScrollData';

export const CotaScroll = (props) => {
  const {
    show,
    close,
    selected,
    declaredScrolls,
    setDeclaredScrolls,
    diceBox,
    showing,
    mode,
    path,
    subpath,
    guildTitle,
    leveledScrolls,
    setLeveledScrolls,
  } = props;

  const [filteredScrolls, setFilteredScrolls] = useState(combatScrolls);
  const [lookingAt, setLookingAt] = useState(showing);
  const [lookingAtLevel, setLookingAtLevel] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isSelectedByThisSlot, setIsSelectedByThisSlot] = useState(false);
  const [isSelectedByAnotherSlot, setIsSelectedByAnotherSlot] = useState(false);

  const contentRef = useRef(null);

  useLayoutEffect(() => {
    if (show && contentRef.current) {
      //setModalHeight(contentRef.current.clientHeight + 80);
    }
  }, [lookingAt, show]);

  useEffect(() => {
    if (showing === -1) {
      setLookingAt(0);
    } else setLookingAt(showing);
  }, [showing]);

  useEffect(() => {
    const updatedScrolls = combatScrolls.filter(
      (scroll) =>
        scroll.path === 'Any' ||
        scroll.path === guildTitle ||
        (scroll.path === path && scroll.subpath === '') ||
        (scroll.path === path && scroll.subpath === subpath)
    );

    setFilteredScrolls(updatedScrolls);
    // Reset lookingAt if it is out of bounds after filtering
    if (lookingAt >= updatedScrolls.length) {
      setLookingAt(0);
    }
  }, [path, subpath, guildTitle]);

  useEffect(() => {
    // Find the level for the currently displayed scroll
    const leveledScroll = leveledScrolls.find(
      (item) => item.scroll === lookingAt
    );
    if (leveledScroll) {
      setLookingAtLevel(leveledScroll.level);
    } else {
      setLookingAtLevel(0); // Default level if not found
    }

    setIsSelectedByThisSlot(declaredScrolls[selected] === lookingAt);
    setIsSelectedByAnotherSlot(
      declaredScrolls.includes(lookingAt) &&
        declaredScrolls[selected] !== lookingAt
    );
  }, [declaredScrolls, lookingAt, selected, leveledScrolls]);

  const roll = (diceEnum) => {
    if (diceEnum !== 'None') {
      diceBox.roll(diceEnum);
    }
  };

  const darkMode = () => (mode === 'dark' ? '_dark' : '');

  useEffect(() => {
    const mediaQueryAspectRatio = window.matchMedia(
      '(max-aspect-ratio: 1510/962)'
    );
    const mediaQueryWidth = window.matchMedia('(max-width: 500px)');

    const handleMediaChange = () => {
      setIsFullScreen(mediaQueryAspectRatio.matches);
      setIsPhone(mediaQueryWidth.matches);
    };

    handleMediaChange(); // Check on component mount
    mediaQueryAspectRatio.addListener(handleMediaChange);
    mediaQueryWidth.addListener(handleMediaChange);

    return () => {
      mediaQueryAspectRatio.removeListener(handleMediaChange);
      mediaQueryWidth.removeListener(handleMediaChange);
    };
  }, []);

  const currentScroll = filteredScrolls[lookingAt];
  const hasLevels = Array.isArray(currentScroll.attackRoll);

  const updateLeveledScrolls = (scrollIndex, newLevel) => {
    setLeveledScrolls((prev) => {
      const existing = prev.find((item) => item.scroll === scrollIndex);
      if (existing) {
        return prev.map((item) =>
          item.scroll === scrollIndex ? { ...item, level: newLevel } : item
        );
      } else {
        return [...prev, { scroll: scrollIndex, level: newLevel }];
      }
    });
  };

  const handleLevelChange = (delta) => {
    if (hasLevels) {
      const newLevel = lookingAtLevel + delta;
      if (newLevel >= 0 && newLevel < currentScroll.attackRoll.length) {
        setLookingAtLevel(newLevel);
        updateLeveledScrolls(lookingAt, newLevel);
      }
    }
  };

  return (
    <Rodal
      animation={'door'}
      visible={show}
      onClose={close}
      customStyles={{
        position: 'absolute',
        border: mode === 'dark' ? '2px solid #393e41' : '2px solid #e4E7C5', // beige
        backgroundColor: mode === 'dark' ? '#26292C' : '#f6f7eb', // ivory
        width: isFullScreen ? 'calc(100% - 34px)' : '550px',
        height: isFullScreen ? 'calc(100% - 114px)' : '580px',
        marginTop: isFullScreen ? '80px' : '',
      }}
    >
      <div
        ref={contentRef}
        className={
          'cota_scroll_modal_content cota_scroll_modal_content' + darkMode()
        }
      >
        {filteredScrolls.length > 0 && (
          <>
            <h3 className='cota_scroll_modal_paragraph'>
              {currentScroll.name}
            </h3>
            <p className='cota_scroll_modal_paragraph'>
              {currentScroll.rarity}
            </p>

            {hasLevels && (
              <>
                <div
                  className={
                    'cota_scroll_modal_divider cota_scroll_modal_divider' +
                    darkMode()
                  }
                />
                <div className='cota_scroll_modal_level'>
                  <span>Level {lookingAtLevel + 1}</span>

                  <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                    <Button
                      onPress={() => handleLevelChange(-1)}
                      label='⟨'
                      disabled={!(lookingAtLevel > 0)}
                      type={mode === 'dark' ? 'onyx' : 'black-white'}
                    />
                  </span>
                  <Button
                    onPress={() => handleLevelChange(1)}
                    label='⟩'
                    type={mode === 'dark' ? 'onyx' : 'black-white'}
                    disabled={
                      !(lookingAtLevel < currentScroll.attackRoll.length - 1)
                    }
                  />
                </div>
              </>
            )}
            <div
              className={
                'cota_scroll_modal_divider cota_scroll_modal_divider' +
                darkMode()
              }
            />
            <p
              onClick={() =>
                roll(
                  hasLevels
                    ? currentScroll.attackRoll[lookingAtLevel]
                    : currentScroll.attackRoll
                )
              }
              className='cota_scroll_modal_paragraph'
            >
              Attack Roll:{' '}
              {hasLevels
                ? currentScroll.attackRoll[lookingAtLevel]
                : currentScroll.attackRoll}{' '}
              🎲
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Defending Stat: {currentScroll.defendingStat}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Priority:{' '}
              {hasLevels
                ? currentScroll.priority[lookingAtLevel]
                : currentScroll.priority}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Damage:{' '}
              {hasLevels
                ? currentScroll.damage[lookingAtLevel]
                : currentScroll.damage}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Target:{' '}
              {hasLevels
                ? currentScroll.target[lookingAtLevel]
                : currentScroll.target}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Keyword: {currentScroll.keyword}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Duration:{' '}
              {hasLevels
                ? currentScroll.duration[lookingAtLevel]
                : currentScroll.duration}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Durable:{' '}
              {hasLevels
                ? currentScroll.durable[lookingAtLevel]
                : currentScroll.durable}
            </p>
            <div
              className={
                'cota_scroll_modal_divider cota_scroll_modal_divider' +
                darkMode()
              }
            />
            <p className='cota_scroll_modal_paragraph'>
              Description: {currentScroll.description}
            </p>
            <p className='cota_scroll_modal_paragraph'>
              Effects:{' '}
              {hasLevels
                ? currentScroll.effects[lookingAtLevel]
                : currentScroll.effects}
            </p>
          </>
        )}
      </div>

      <div className='cota_scroll_modal_footer'>
        <Button
          onPress={() => setLookingAt(lookingAt - 1)}
          label='⟨'
          disabled={!(lookingAt > 0)}
          type={mode === 'dark' ? 'onyx' : 'black-white'}
        />
        <span style={{ marginLeft: '10px', marginRight: '10px' }}>
          <Button
            onPress={() => {
              const newDeclaredScrolls = [...declaredScrolls];
              newDeclaredScrolls[selected] = lookingAt;
              setDeclaredScrolls(newDeclaredScrolls);
            }}
            label='Select'
            type={
              isSelectedByThisSlot
                ? 'cinnabar'
                : mode === 'dark'
                ? 'onyx'
                : 'black-white'
            }
            disabled={isSelectedByAnotherSlot}
          />
        </span>
        <Button
          onPress={() => setLookingAt(lookingAt + 1)}
          label='⟩'
          type={mode === 'dark' ? 'onyx' : 'black-white'}
          disabled={!(lookingAt < filteredScrolls.length - 1)}
        />
        <span
          style={{ position: 'absolute', marginLeft: '10px', right: '10px' }}
        >
          <Button
            onPress={() => {
              const newDeclaredScrolls = [...declaredScrolls];
              newDeclaredScrolls[selected] = -1;
              setDeclaredScrolls(newDeclaredScrolls);
            }}
            label={isPhone ? '↻' : 'Remove'}
            type={mode === 'dark' ? 'onyx' : 'black-white'}
            disabled={!isSelectedByThisSlot}
          />
        </span>
      </div>
    </Rodal>
  );
};
