import { useState, useEffect } from 'react';
//import cotastatsimg from '../shared/assets/charactersheets/cotastats.png';
//import cotalogo from '../shared/assets/logos/cotalogo.png';
//import cotatitle from '../shared/assets/logos/cotatitle.png';
import { useNavigate } from 'react-router-dom';
import '../shared/styles/Page.css';
import { Button } from '../shared/components/Button';
import dfLogo from '../shared/assets/logos/dimensionfactory.png';

export default function TopBar({ mode, setMode }) {
  const navigate = useNavigate();

  const doLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('system');
    localStorage.removeItem('campaign');
    setTimeout(() => {
      navigate('/');
    }, '200');
  };

  return (
    <div style={{ width: '100%' }}>
      <img
        style={{
          top: '5px',
          left: '10px',
          height: '70px',
          position: 'absolute',
        }}
        alt=""
        src={dfLogo}
      />

      <span style={{ top: '6px', right: '10px', position: 'absolute' }}>
        <Button
          icon={'logout'}
          type={mode === 'dark' ? 'onyx' : 'cinnabar'}
          onPress={() => {
            doLogout();
          }}
        />
      </span>

      <span style={{ top: '6px', right: '95px', position: 'absolute' }}>
        <Button
          icon={mode === 'dark' ? 'lightmode' : 'darkmode'}
          type={mode === 'dark' ? 'onyx' : 'cinnabar'}
          onPress={() => {
            if (mode === 'dark') {
              setMode('light');
            } else {
              setMode('dark');
            }
          }}
        />
      </span>
    </div>
  );
}
