import Rodal from 'rodal';
import { useState, useEffect, useRef } from 'react';
import { Button } from '../../../shared/components/Button';
import 'rodal/lib/rodal.css';
import '../../../shared/styles/Cota.css';
import { combatScrolls } from './CotaScrollData';

export const CotaInventory = (props) => {
  const { show, close, mode } = props;
  const [obols, setObols] = useState('');
  const [scrollFragments, setScrollFragments] = useState('');
  const [inventoryNotes, setInventoryNotes] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef(null);

  const handleObolsChange = (event) => {
    setObols(event.target.value);
  };

  const handleScrollFragmentsChange = (event) => {
    setScrollFragments(event.target.value);
  };

  const handleNotesChange = (event) => {
    setInventoryNotes(event.target.value);
  };

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-aspect-ratio: 1510/962)');

    const handleMediaChange = (e) => {
      setIsFullScreen(e.matches);
    };
    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  return (
    <Rodal
      animation={'door'}
      visible={show}
      onClose={close}
      customStyles={{
        position: 'absolute',
        border: mode === 'dark' ? '2px solid #393e41' : '2px solid #e4E7C5', // beige
        backgroundColor: mode === 'dark' ? '#26292C' : '#f6f7eb', // ivory
        width: isFullScreen ? 'calc(100% - 34px)' : '350px',
        height: isFullScreen ? 'calc(100% - 114px)' : '562px',
        marginTop: isFullScreen ? '80px' : '',
      }}
    >
      <div
        ref={contentRef}
        className={'cota_scroll_modal_content cota_scroll_modal_content' + darkMode()}
      >
        <h3 className='cota_scroll_modal_paragraph'>Inventory</h3>
        <div className={'cota_scroll_modal_divider cota_scroll_modal_divider' + darkMode()} />
        Obols:
        <textarea
          className='cota_notes_modal_notes'
          value={obols}
          onChange={handleObolsChange}
        />
        Scroll Fragments
        <textarea
          className='cota_notes_modal_notes'
          value={scrollFragments}
          onChange={handleScrollFragmentsChange}
        />
        <textarea
          className='cota_notes_modal_notes'
          value={inventoryNotes}
          onChange={handleNotesChange}
        />
      </div>
    </Rodal>
  );
};
