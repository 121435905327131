import '../../../shared/styles/Dice.css';
import { useState, useEffect } from 'react';
import cotastatsimg from '../../../shared/assets/charactersheets/cotastats.png';
import cotastatsdarkimg from '../../../shared/assets/charactersheets/cotastatsdark.png';
import cotalogo from '../../../shared/assets/logos/cotalogo.png';
import enlightenedlogo from '../../../shared/assets/logos/enlightenedlogo.png';
import honorboundlogo from '../../../shared/assets/logos/honorboundlogo.png';
import ironsteamlogo from '../../../shared/assets/logos/ironsteamlogo.png';
import wildheartlogo from '../../../shared/assets/logos/wildheartlogo.png';
import selectguildlogo from '../../../shared/assets/logos/selectguildlogo.png';
import DiceBox from '@3d-dice/dice-box';
import '../../../shared/styles/Cota.css';
import CotaScrollGrid from './CotaScrollGrid';
import CotaRPAbility from './CotaRPAbility';
import { CotaScroll } from './CotaScroll';
import CotaPotion from './CotaPotion';
import { CotaNotes } from './CotaNotes';
import { CotaInventory } from './CotaInventory';
import { CotaGuild } from './CotaGuild';
import { CotaPath } from './CotaPath';

export default function CotaCharacterSheet({ mode, diceBox }) {
  const [characterName, setCharacterName] = useState('');
  const [characterTitle, setCharacterTitle] = useState('');
  const [characterLevel, setCharacterLevel] = useState(1);
  /* Must be an integer from 1 to 20 */
  const [characterXP, setCharacterXP] = useState(0);
  /* Must be related to level, and base on level, there are available Bonus stat Points
    -------------------------------------------
    XP      Level   Available Bonus Stat Points
    -------------------------------------------
    0	      1	      2
    300	    2       4
    900	    3       6
    2700	  4       8
    6500	  5       11
    14000	  6       14
    23000	  7       17
    34000	  8       20
    48000	  9       24
    64000	  10      28
    85000	  11      32
    100000	12      36
    120000	13      41
    140000	14      46
    165000	15      51
    195000	16      56
    225000	17      62
    265000	18      68
    305000	19      74
    355000	20      80
  */
  const [characterAncestry, setCharacterAncestry] = useState('');
  /* Must be one of the following:
    Human  
    Dwarf  
    Elf 
    Goblin  
    Merfolk
    Raptorborn  
    Dragonborn  
    Basiliskborn
    Ghoul  
    Zeilf  
    Halfling
    Throne  
    Sphinx  
    Deva  
    Leviathan
  */
  const [characterAlignment, setCharacterAlignment] = useState('');
  /* Must be one of the following:
    Neutral Good
    Chaotic Good
    Lawful Neutral
    True Neutral
    Chaotic Neutral
    Lawful Evil
    Neutral Evil
  */
  const [characterStrength, setCharacterStrength] = useState(1); //Must be between 1 and 30
  const [characterDexterity, setCharacterDexterity] = useState(1); //Must be between 1 and 30
  const [characterConstitution, setCharacterConstitution] = useState(1); //Must be between 1 and 30
  const [characterIntelligence, setCharacterIntelligence] = useState(1); //Must be between 1 and 30
  const [characterWisdom, setCharacterWisdom] = useState(1); //Must be between 1 and 30
  const [characterCharisma, setCharacterCharisma] = useState(1); //Must be between 1 and 30

  const [characterStrengthSavingThrow, setCharacterStrengthSavingThrow] =
    useState(false);
  const [characterDexteritySavingThrow, setCharacterDexteritySavingThrow] =
    useState(false);
  const [
    characterConstitutionSavingThrow,
    setCharacterConstitutionSavingThrow,
  ] = useState(false);
  const [
    characterIntelligenceSavingThrow,
    setCharacterIntelligenceSavingThrow,
  ] = useState(false);
  const [characterWisdomSavingThrow, setCharacterWisdomSavingThrow] =
    useState(false);
  const [characterCharismaSavingThrow, setCharacterCharismaSavingThrow] =
    useState(false);

  const [characterSkillProficiency] = useState(4); //[CHANGE] How many available proficiencies for Skills. Depends on Level.
  /*[CHANGE] How many available proficiencies for Skills. Depends on Level.
    ------------------------------
    Skill Proficiencies    Levels 
    ------------------------------
    4                      1-4
    5                      5-8
    6                      9-12
    7                      13-16
    8                      17-20
  */

  const [CharacterSkillMastery] = useState('', '', ''); //[CHANGE] How many available proficiencies for Skills. Depends on Achievements. 3 is Maximum.

  /*[CHANGE] How many available proficiencies for Skills. Depends on Achievements. 3 is Maximum.
    =============================================================================
    [For Coves of Elvion]
    -----------------------------------------------------------------------------
    Skill                    Mastery Condition
    -----------------------------------------------------------------------------
    Acrobatics               Jump between Realms
    Animal Handling          Write the missing entries of the Bestiary __/5
    Arcana                   Use Odd and the Odds to rewrite a Great Spellforge
    Athletics                Swim around Elvion
    Deception                Deceive your way into the 3 castles  __/3
    History                  Land the coin on the un-carved side
    Insight                  Resist the urge to roll the Dice
    Intimidation             Equip Scarlet, Crimson and Obsidian all at once
    Investigation            Find whose Solve the Land dispute
    Medicine                 Heal Echoes __/5/10
    Nature                   Visit Areas of Elvion __/8/10
    Perception               Catch the shapeshift in the act
    Performance              Learn songs _/5
    Persuasion               Persuade the Queen for the Arkians to move to Elvion
    Religion                 Visit Inner Sanctums __/2
    Sleight of Hand          Read all scrolls of the Taking __/5
    Stealth                  Enter the cove of Engin without being discovered
    Survival                 Survive an expedition with only the Destiny Row
    =============================================================================
    [For The Cradle]
    -----------------------------------------------------------------------------
    Skill                    Mastery Condition
    -----------------------------------------------------------------------------
    Acrobatics               Successfully Go to   to get a Resari Stone
    Animal Handling          W
    Arcana                   
    Athletics                
    Deception                
    History                  
    Insight                  
    Intimidation             
    Investigation            
    Medicine                 
    Nature                   
    Perception               
    Performance              
    Persuasion               
    Religion                 
    Sleight of Hand          
    Stealth                  
    Survival                 
    =============================================================================
    [For Mother Sea]
    -----------------------------------------------------------------------------
    Skill                    Mastery Condition
    -----------------------------------------------------------------------------
    Acrobatics               Be accepted into the Circus of Hearts
    Animal Handling          Find Rare Beasts __/50/100
    Arcana                   Learn Lvl 20 Spells __/4
    Athletics                Learn Lvl 20 Stances __/4                                   
    Deception                Deceive your way into becoming a Herald of Rignalia
    History                  Read Books __/200
    Insight                  Discover the Principium by uncovering Cults __/3
    Intimidation             Win the Throne of an Evmier King
    Investigation            Register an authentic Cradle Piece
    Medicine                 Heal for 60HP in One Action
    Nature                   Find Rare Plants __/50/100
    Perception               Visit Kingdoms __/80/100
    Performance              Be accepted into the Grand Hall of the Arts
    Persuasion               Be elected as the White Captain by the Pax's Senate
    Religion                 Preach your way into a Seat of Zehelium
    Sleight of Hand          Write on Kasma's Journal
    Stealth                  Become one of the anonymous Quilis Lords
    Survival                 Reach the Depths of the Helvian Trench
    ============================================================================
  */

  const [characterAthletics] = useState(0);

  const [characterGuild, setCharacterGuild] = useState('None'); // Enlightened, Ηonorbound, Ironsteam, Wildheart, None
  const [characterGuildTitle, setCharacterGuildTitle] = useState('Member'); // Archon, Herald, Ambassador, Librarian, Member
  const [path, setPath] = useState('');
  const [subpath, setSubpath] = useState('');
  const [subpathRPAbilityPlace, setSubpathRPAbilityPlace] = useState('None'); //1, 2, 3, 'None'
  const [temporaryHitPoints, setTemporaryHitPoints] = useState('');
  const [currentHitPoints, setCurrentHitPoints] = useState('');
  const [hitPointsMaximum, setHitPointsMaximum] = useState('');
  const [divineInfluence, setDivineInfluence] = useState('');
  const [agilityDefense, setAgilityDefense] = useState('');
  const [intuitionDefense, setIntuitionDefense] = useState('');
  const [might, setMight] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [divination, setDivination] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [agility, setAgility] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [intuition, setIntuition] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [characterImage, setCharacterImage] = useState('');
  const [declaredScrolls, setDeclaredScrolls] = useState([
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ]);
  const [leveledScrolls, setLeveledScrolls] = useState([]);
  const [selectedScroll, setSelectedScroll] = useState();

  const [showingScroll, setShowingScroll] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showPathSelect, setShowPathSelect] = useState(false);
  const [showGuildSelect, setShowGuildSelect] = useState(false);

  const [isPhone, setIsPhone] = useState(false);
  const [pathOrSubPathSelection, setPathOrSubPathSelection] = useState('path');
  const [levelIsFocused, setLevelIsFocused] = useState(false);

  const openModal = (selected, showing) => {
    setSelectedScroll(selected);
    setShowingScroll(showing);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedScroll();
    setShowingScroll(0);
    setShowModal(false);
  };

  const openNotes = () => {
    setShowNotes(true);
  };

  const closeNotes = () => {
    setShowNotes(false);
  };

  const openInventory = () => {
    setShowInventory(true);
  };

  const closeInventory = () => {
    setShowInventory(false);
  };

  const openPathSelect = (isPathOrSubpath) => {
    setPathOrSubPathSelection(isPathOrSubpath);
    setShowPathSelect(true);
  };

  const closePathSelect = () => {
    setShowPathSelect(false);
    setTimeout(() => {
      setPathOrSubPathSelection('path');
    }, 200); //Time it takes for animation to close
  };

  const openGuildSelect = () => {
    setShowGuildSelect(true);
  };

  const closeGuildSelect = () => {
    setShowGuildSelect(false);
  };

  useEffect(() => {
    const calculateStatCount = (statArray) => {
      return statArray.filter((value) => value).length;
    };
    setHitPointsMaximum(20 + 5 * calculateStatCount(might));
    setIntuitionDefense(`${calculateStatCount(intuition) + 1}d6`);
    setAgilityDefense(`${calculateStatCount(agility) + 1}d6`);
    setDivineInfluence(`${calculateStatCount(divination) + 2}`);
  }, [might, divination, agility, intuition]);

  useEffect(() => {
    const savedImage = localStorage.getItem('characterImage');
    if (savedImage) {
      setCharacterImage(savedImage);
    }
    const savedData = localStorage.getItem('characterData');
    if (savedData) {
      const {
        level,
        characterName,
        characterTitle,
        characterGuild,
        characterGuildTitle,
        path,
        subpath,
        subpathRPAbilityPlace,
        temporaryHitPoints,
        currentHitPoints,
        divineInfluence,
        agilityDefense,
        intuitionDefense,
        might,
        divination,
        agility,
        intuition,
        declaredScrolls,
        leveledScrolls,
      } = JSON.parse(savedData);

      setCharacterName(characterName);
      setCharacterTitle(characterTitle);
      setCharacterGuild(characterGuild);
      setCharacterGuildTitle(characterGuildTitle);
      setPath(path);
      setSubpath(subpath);
      setSubpathRPAbilityPlace(subpathRPAbilityPlace);
      setTemporaryHitPoints(temporaryHitPoints);
      setCurrentHitPoints(currentHitPoints);
      setDivineInfluence(divineInfluence);
      setAgilityDefense(agilityDefense);
      setIntuitionDefense(intuitionDefense);
      setMight(might);
      setDivination(divination);
      setAgility(agility);
      setIntuition(intuition);
      setDeclaredScrolls(declaredScrolls);
      setLeveledScrolls(leveledScrolls);
    }

    const mediaQueryWidth = window.matchMedia('(max-width: 500px)');
    const handleMediaChange = () => {
      setIsPhone(mediaQueryWidth.matches);
    };
    handleMediaChange(); // Check on component mount
    mediaQueryWidth.addListener(handleMediaChange);
    return () => {
      mediaQueryWidth.removeListener(handleMediaChange);
    };
  }, []);

  useEffect(() => {
    const characterData = {
      characterName,
      characterTitle,
      characterGuild,
      characterGuildTitle,
      path,
      subpath,
      subpathRPAbilityPlace,
      temporaryHitPoints,
      currentHitPoints,
      divineInfluence,
      agilityDefense,
      intuitionDefense,
      might,
      divination,
      agility,
      intuition,
      declaredScrolls,
      leveledScrolls,
    };
    localStorage.setItem('characterData', JSON.stringify(characterData));
  }, [
    characterName,
    characterTitle,
    characterGuild,
    characterGuildTitle,
    path,
    subpath,
    subpathRPAbilityPlace,
    temporaryHitPoints,
    currentHitPoints,
    divineInfluence,
    agilityDefense,
    intuitionDefense,
    might,
    divination,
    agility,
    intuition,
    declaredScrolls,
    leveledScrolls,
  ]);

  const handleToggleCotaHex = (index, setState, state) => {
    const newState = [...state];
    newState[index] = !newState[index];
    setState(newState);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      localStorage.setItem('characterImage', reader.result);
      setCharacterImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const rollDice = (stat) => {
    const roll = (diceEnum) => {
      diceBox.roll(diceEnum);
    };
    const addRoll = () => {
      diceBox.add('1d6');
    };
    const calculateDiceCount = (statArray) => {
      return statArray.filter((value) => value).length + 1;
    };
    if (stat === 'might') {
      roll(`${calculateDiceCount(might)}d6`);
    } else if (stat === 'divination') {
      roll(`${calculateDiceCount(divination)}d6`);
    } else if (stat === 'agility') {
      roll(`${calculateDiceCount(agility)}d6`);
    } else if (stat === 'intuition') {
      roll(`${calculateDiceCount(intuition)}d6`);
    } else if (stat === 'divine influence') {
      const divineInfluenceNum = Number(divineInfluence);
      if (
        !isNaN(divineInfluenceNum) &&
        Number.isInteger(divineInfluenceNum) &&
        divineInfluenceNum >= 1
      ) {
        setDivineInfluence(+(divineInfluenceNum - 1));
        addRoll();
      }
    } else {
      roll(`1d6`);
    }
  };

  /*
  const handleLevelInput = (e) => {
    const value = e.target.innerText;
    if (/^\d+$/.test(value) && Number(value) >= 1) {
      setLevel(value);
    } else {
      e.target.innerText = level;
    }
  };

  const handleNonEditableInput = (e, setter, value) => {
    e.target.innerText = value;
  };
  */

  const handlePathChange = (path) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setPath(path);
    setSubpath('');
  };

  const handleSubPathChange = (subpath) => {
    setSubpath(subpath);
  };

  const handleRemoveSubPath = () => {
    setSubpath('');
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setSubpathRPAbilityPlace('None');
  };

  const handleGuildChange = (guild) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setCharacterGuild(guild);
  };

  const handleGuildTitleChange = (guildTitle) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setCharacterGuildTitle(guildTitle);
  };

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return;
  };

  return (
    <div className='page_content'>
      <div className={'cota_character_sheet cota_character_sheet' + darkMode()}>
        <span className='cota_character_sheet_left'>
          <div className='cota_character_sheet_left_whole'>
            <div className='cota_character_page_1 cota_decorative_outline'>
              <div className='cota_stats_spacer' />
              <div className='cota_header_container'>
                <div className='cota_header'>Header</div>
                Stats
              </div>
              <div className='cota_stats_spacer' />
            </div>
          </div>
        </span>
        <span className='cota_character_sheet_right'>
          <div className='cota_character_sheet_right_up'>
            <div className='cota_character_page_2 cota_decorative_outline'>
              <div className='cota_stats_spacer' />

              <div className='cota_rp_abilities'>Items</div>
            </div>
          </div>
          Action Board
        </span>
      </div>
      <CotaScroll
        mode={mode}
        show={showModal}
        close={closeModal}
        selected={selectedScroll}
        showing={showingScroll}
        declaredScrolls={declaredScrolls}
        setDeclaredScrolls={setDeclaredScrolls}
        leveledScrolls={leveledScrolls}
        setLeveledScrolls={setLeveledScrolls}
        diceBox={diceBox}
        path={path}
        subpath={subpath}
        guildTitle={characterGuild + ' ' + characterGuildTitle}
      />
      <CotaNotes mode={mode} show={showNotes} close={closeNotes} />
      <CotaInventory mode={mode} show={showInventory} close={closeInventory} />
      <CotaGuild
        mode={mode}
        show={showGuildSelect}
        close={closeGuildSelect}
        characterGuild={characterGuild}
        handleGuildChange={handleGuildChange}
        characterGuildTitle={characterGuildTitle}
        handleGuildTitleChange={handleGuildTitleChange}
      />
      <CotaPath
        mode={mode}
        show={showPathSelect}
        close={closePathSelect}
        path={path}
        handlePathChange={handlePathChange}
        subpath={subpath}
        handleSubPathChange={handleSubPathChange}
        subpathRPAbilityPlace={subpathRPAbilityPlace}
        setSubpathRPAbilityPlace={setSubpathRPAbilityPlace}
        isPathOrSubpath={pathOrSubPathSelection}
      />
    </div>
  );
}
